import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/open-animation.css";
import {
	withNavigationHandlers,
	withNavigationContext,
} from "react-awesome-slider/dist/navigation";
import { media } from "./Media";
import { useNavigate } from 'react-router-dom';

const ContentSlider = withNavigationHandlers(AwesomeSlider);

export default withNavigationContext(({ fullpage }) => {
	const navigate = useNavigate();
	const handleAnimationEnd = ({ currentIndex }) => {
		if (media[currentIndex].slug !== fullpage.navigation.slug) {
			navigate(`/${media[currentIndex].slug}`);
		}
	};

	return (
		<ContentSlider
			animation="openAnimation"
			className="slider"
			transitionDelay={100}
			infinite
			media={media}
			onTransitionEnd={handleAnimationEnd}
		/>
	);
});

import React from "react";
import { Link } from "react-router-dom";
import "./PlanetLink.css";

export default function PlanetLink({ image, to, title, download }) {
	return (
		<div className="linkWrap">
			<Link to={to} target={download ? "_blank" : ""}>
				<img src={image} alt={to + "avi"} />
				<h2>{title}</h2>
			</Link>
		</div>
	);
}
